// ** Reducers Imports
import layout from "./layout"
import auth from "./authentication"
import profile from "@src/views/profile/store"
import financialSupport from "./financialSupport"
import companies from "./companies"
import services from "./services"
import notifications from "./notifications"
import executives from "./executives"
import dashboards from "./dashboards"
import businessSectors from "./businessSectors"
import archivenomies from "./archivenomies"
import partners from "./partners"
import requests from "./requests"
import archivenomiesYears from "./archivenomiesYears"
import areas from "./areas"
import industries from "./industries"
import branches from "./branches"
import businessOpportunities from "./businessOpportunities"
import countries from "./countries"
import configSupports from "./configSupports"
import subservices from "./subservices"
import buyers from "./buyers"
import currencies from "./currencies"
import buyerSupports from "./buyerSupports"
import histories from "./histories"
import tradeMatch from "./tradeMatch"

const rootReducer = {
  auth,
  layout,
  profile,
  financialSupport,
  companies,
  services,
  notifications,
  executives,
  dashboards,
  businessSectors,
  archivenomies,
  partners,
  requests,
  archivenomiesYears,
  areas,
  industries,
  branches,
  businessOpportunities,
  countries,
  configSupports,
  subservices,
  buyers,
  currencies,
  buyerSupports,
  histories,
  tradeMatch
}

export default rootReducer
