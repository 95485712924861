// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"

// ** Constants
import { API_BRANCHES_URL } from "../constants"

export const getListBranchesForGto = createAsyncThunk(
  "branches/getListBranchesForGto",
  async ({
    page = 1,
    limit = 20,
    search,
    industry
  } = {}) => {
    let queryParams = `page=${page}&limit=${limit}`
    if (search) {
      queryParams += `&search=${search}`
    }
    if (industry) {
      queryParams += `&industry=${industry}`
    }
    const response = await axios.get(`${API_BRANCHES_URL}/for/gto-supply?${queryParams}`)
    return response.data
  }
)

export const branchesSlice = createSlice({
  name: "branches",
  initialState: {
    listForGto: []
  },
  reducers: {}
})

export default branchesSlice.reducer