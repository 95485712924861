// ** React Imports
import { lazy } from "react"

const Profile = lazy(() => import("../../views/profile"))
const Statistics = lazy(() => import("../../views/statistics"))
const FinancialSupportList = lazy(() => import("../../views/financialSupport/list"))
const FinancialSupportRequest = lazy(() => import("../../views/financialSupport/request"))
const FinancialSupportToSend = lazy(() => import("../../views/financialSupport/toSend"))
const FinancialSupportTracing = lazy(() => import("../../views/financialSupport/tracing"))
const FinancialSupportHistoric = lazy(() => import("../../views/financialSupport/historic"))
const ServicesRequest = lazy(() => import("../../views/services/request"))
const ServicesTracing = lazy(() => import("../../views/services/tracing"))
const ServicesHistory = lazy(() => import("../../views/services/history"))
const BusinessOpportunitiesList = lazy(() => import("../../views/businessOpportunities/list"))
const BusinessOpportunitiesApplications = lazy(() => import("../../views/businessOpportunities/applications"))
const Tradematch = lazy(() => import("../../views/tradematch"))

// ADMIN
const FinancialSupportTracingAdmin = lazy(() => import("../../views/admin/financialSupport/tracing"))
const FinancialSupportHistoricAdmin = lazy(() => import("../../views/admin/financialSupport/historic"))
const FinancialSupportCanceledAdmin = lazy(() => import("../../views/admin/financialSupport/canceled"))
const FinancialSupportDesistedAdmin = lazy(() => import("../../views/admin/financialSupport/desisted"))
const FinancialSupportReview = lazy(() => import("../../views/admin/financialSupport/review"))

// SUPER ADMIN
const SettingsStatisticsCompanies = lazy(() => import("../../views/superadmin/settings/statistics/companies"))
const SettingsStatisticsPartners = lazy(() => import("../../views/superadmin/settings/statistics/partners"))
const SettingsStatisticsTradematch = lazy(() => import("../../views/superadmin/settings/statistics/tradematch"))
const SettingsCredentialsCompanies = lazy(() => import("../../views/superadmin/settings/credentials/companies"))
const SettingsCredentialsPartners = lazy(() => import("../../views/superadmin/settings/credentials/partners"))
const SettingsBusinessSectors = lazy(() => import("../../views/superadmin/settings/businessSectors"))
const SettingsRoles = lazy(() => import("../../views/superadmin/settings/roles"))
const SettingsBenefits = lazy(() => import("../../views/superadmin/settings/benefits"))
const SettingsFinancialSupports = lazy(() => import("../../views/superadmin/settings/financialSupports"))

// USER
const StatisticsUser = lazy(() => import("../../views/user/statistics"))
const CompaniesUser = lazy(() => import("../../views/user/companies"))
const FinancialSupportListBuyers = lazy(() => import("../../views/user/buyers/financialSupport/list"))
const FinancialSupportRequestBuyers = lazy(() => import("../../views/user/buyers/financialSupport/request"))
const FinancialSupportToSendBuyers = lazy(() => import("../../views/user/buyers/financialSupport/toSend"))
const FinancialSupportTracingBuyers = lazy(() => import("../../views/user/buyers/financialSupport/tracing"))
const FinancialSupportHistoricBuyers = lazy(() => import("../../views/user/buyers/financialSupport/historic"))
const FinancialSupportCanceledBuyers = lazy(() => import("../../views/user/buyers/financialSupport/canceled"))
const FinancialSupportDesistedBuyers = lazy(() => import("../../views/user/buyers/financialSupport/desisted"))
const FinancialSupportTracingUser = lazy(() => import("../../views/user/financialSupport/tracing"))
const FinancialSupportHistoricUser = lazy(() => import("../../views/user/financialSupport/historic"))
const FinancialSupportCanceledUser = lazy(() => import("../../views/user/financialSupport/canceled"))
const FinancialSupportDesistedUser = lazy(() => import("../../views/user/financialSupport/desisted"))

// ARCHIVENOMY
const Archivenomy = lazy(() => import("../../views/archivenomy"))
const ArchivenomyList = lazy(() => import("../../views/archivenomy/list"))
const ArchivenomyDetails = lazy(() => import("../../views/archivenomy/details"))

// PARTNER
const StatisticsPartner = lazy(() => import("../../views/partner/statistics"))

// TEST
const Test = lazy(() => import("../../views/test"))

// PUBLIC
const BusinessOpportunitiesDetails = lazy(() => import("../../views/pages/businessOpportunities"))

const AppRoutes = [
  {
    element: <Profile />,
    path: "/profile",
    meta: {
      action: 'read',
      resource: 'Profile'
    }
  },
  {
    element: <Statistics />,
    path: "/statistics",
    meta: {
      action: 'read',
      resource: 'Statistics'
    }
  },
  {
    element: <Tradematch />,
    path: "/tradematch",
    meta: {
      action: 'read',
      resource: 'Tradematch'
    }
  },
  {
    element: <FinancialSupportList />,
    path: "/financial-support/list",
    meta: {
      action: 'read',
      resource: 'FinancialSupportList'
    }
  },
  {
    element: <FinancialSupportRequest />,
    path: "/financial-support/request/:id",
    meta: {
      action: 'read',
      resource: 'FinancialSupportRequest'
    }
  },
  {
    element: <FinancialSupportToSend />,
    path: "/financial-support/toSend",
    meta: {
      action: 'read',
      resource: 'FinancialSupportToSend'
    }
  },
  {
    element: <FinancialSupportTracing />,
    path: "/financial-support/tracing",
    meta: {
      action: 'read',
      resource: 'FinancialSupportTracing'
    }
  },
  {
    element: <FinancialSupportHistoric />,
    path: "/financial-support/historic",
    meta: {
      action: 'read',
      resource: 'FinancialSupportHistoric'
    }
  },
  {
    element: <BusinessOpportunitiesList />,
    path: "/business-opportunities/list",
    meta: {
      action: 'read',
      resource: 'BusinessOpportunitiesList'
    }
  },
  {
    element: <BusinessOpportunitiesApplications />,
    path: "/business-opportunities/applications",
    meta: {
      action: 'read',
      resource: 'BusinessOpportunitiesApplications'
    }
  },
  {
    element: <ServicesRequest />,
    path: "/services/request",
    meta: {
      action: 'read',
      resource: 'ServicesRequest'
    }
  },
  {
    element: <ServicesTracing />,
    path: "/services/tracing",
    meta: {
      action: 'read',
      resource: 'ServicesTracing'
    }
  },
  {
    element: <ServicesHistory />,
    path: "/services/history",
    meta: {
      action: 'read',
      resource: 'ServicesHistory'
    }
  },
  {
    element: <FinancialSupportTracingAdmin />,
    path: "/admin/financial-support/tracing",
    meta: {
      action: 'read',
      resource: 'FinancialSupportTracingAdmin'
    }
  },
  {
    element: <FinancialSupportHistoricAdmin />,
    path: "/admin/financial-support/historic",
    meta: {
      action: 'read',
      resource: 'FinancialSupportHistoricAdmin'
    }
  },
  {
    element: <FinancialSupportCanceledAdmin />,
    path: "/admin/financial-support/canceled",
    meta: {
      action: 'read',
      resource: 'FinancialSupportCanceledAdmin'
    }
  },
  {
    element: <FinancialSupportDesistedAdmin />,
    path: "/admin/financial-support/desisted",
    meta: {
      action: 'read',
      resource: 'FinancialSupportDesistedAdmin'
    }
  },
  {
    element: <FinancialSupportReview />,
    path: "/admin/financial-support/review/:id",
    meta: {
      action: 'read',
      resource: 'FinancialSupportReview'
    }
  },
  {
    element: <SettingsStatisticsCompanies />,
    path: "/superadmin/settings/statistics/companies",
    meta: {
      action: 'read',
      resource: 'SettingsStatisticsCompanies'
    }
  },
  {
    element: <SettingsStatisticsPartners />,
    path: "/superadmin/settings/statistics/partners",
    meta: {
      action: 'read',
      resource: 'SettingsStatisticsPartners'
    }
  },
  {
    element: <SettingsStatisticsTradematch />,
    path: "/superadmin/settings/statistics/tradematch",
    meta: {
      action: 'read',
      resource: 'SettingsStatisticsPartners'
    }
  },
  {
    element: <SettingsCredentialsCompanies />,
    path: "/superadmin/settings/credentials/companies",
    meta: {
      action: 'read',
      resource: 'SettingsCredentialsCompanies'
    }
  },
  {
    element: <SettingsCredentialsPartners />,
    path: "/superadmin/settings/credentials/partners",
    meta: {
      action: 'read',
      resource: 'SettingsCredentialsPartners'
    }
  },
  {
    element: <SettingsBusinessSectors />,
    path: "/superadmin/settings/business-sectors",
    meta: {
      action: 'read',
      resource: 'SettingsBusinessSectors'
    }
  },
  {
    element: <SettingsBenefits />,
    path: "/superadmin/settings/sync-benefits",
    meta: {
      action: 'read',
      resource: 'SettingsBusinessSectors'
    }
  },
  {
    element: <SettingsFinancialSupports />,
    path: "/superadmin/settings/supports",
    meta: {
      action: 'read',
      resource: 'SettingsBusinessSectors'
    }
  },
  {
    element: <SettingsRoles />,
    path: "/superadmin/settings/roles",
    meta: {
      action: 'read',
      resource: 'SettingsRoles'
    }
  },
  {
    element: <StatisticsUser />,
    path: "/user/statistics",
    meta: {
      action: 'read',
      resource: 'StatisticsUser'
    }
  },
  {
    element: <CompaniesUser />,
    path: "/user/companies",
    meta: {
      action: 'read',
      resource: 'Companies'
    }
  },
  {
    element: <FinancialSupportListBuyers />,
    path: "/user/buyers/financial-support/list",
    meta: {
      action: 'read',
      resource: 'FinancialSupportListBuyers'
    }
  },
  {
    element: <FinancialSupportToSendBuyers />,
    path: "/user/buyers/financial-support/toSend",
    meta: {
      action: 'read',
      resource: 'FinancialSupportToSendBuyers'
    }
  },
  {
    element: <FinancialSupportTracingBuyers />,
    path: "/user/buyers/financial-support/tracing",
    meta: {
      action: 'read',
      resource: 'FinancialSupportTracingBuyers'
    }
  },
  {
    element: <FinancialSupportHistoricBuyers />,
    path: "/user/buyers/financial-support/historic",
    meta: {
      action: 'read',
      resource: 'FinancialSupportHistoricBuyers'
    }
  },
  {
    element: <FinancialSupportCanceledBuyers />,
    path: "/user/buyers/financial-support/canceled",
    meta: {
      action: 'read',
      resource: 'FinancialSupportCanceledBuyers'
    }
  },
  {
    element: <FinancialSupportDesistedBuyers />,
    path: "/user/buyers/financial-support/desisted",
    meta: {
      action: 'read',
      resource: 'FinancialSupportDesistedBuyers'
    }
  },
  {
    element: <FinancialSupportRequestBuyers />,
    path: "/user/buyers/financial-support/request/:id",
    meta: {
      action: 'read',
      resource: 'FinancialSupportRequestBuyers'
    }
  },
  {
    element: <FinancialSupportTracingUser />,
    path: "/user/financial-support/tracing",
    meta: {
      action: 'read',
      resource: 'FinancialSupportTracingUser'
    }
  },
  {
    element: <FinancialSupportHistoricUser />,
    path: "/user/financial-support/historic",
    meta: {
      action: 'read',
      resource: 'FinancialSupportHistoricUser'
    }
  },
  {
    element: <FinancialSupportCanceledUser />,
    path: "/user/financial-support/canceled",
    meta: {
      action: 'read',
      resource: 'FinancialSupportCanceledUser'
    }
  },
  {
    element: <FinancialSupportDesistedUser />,
    path: "/user/financial-support/desisted",
    meta: {
      action: 'read',
      resource: 'FinancialSupportCanceledUser'
    }
  },
  {
    element: <ArchivenomyDetails />,
    path: "/archivenomy/details/:id",
    meta: {
      action: 'read',
      resource: 'ArchivenomyDetails'
    }
  },
  {
    element: <Archivenomy />,
    path: "/archivenomy",
    meta: {
      action: 'read',
      resource: 'Archivenomy'
    }
  },
  {
    element: <ArchivenomyList />,
    path: "/archivenomy/list/:area/:year",
    meta: {
      action: 'read',
      resource: 'Archivenomy'
    }
  },
  {
    element: <StatisticsPartner />,
    path: "/partner/statistics",
    meta: {
      action: 'read',
      resource: 'StatisticsPartner'
    }
  },
  {
    path: '/business-opportunities/details/:folio',
    element: <BusinessOpportunitiesDetails />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  }
]

export default AppRoutes
